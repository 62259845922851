import React from "react";
import Layout from "../components/Layout";


export default function SchoolCommittees() {

    const embedStyle = {
        marginTop: '30px',
        maxWidth: '800px',
        width: '100%',
        margin: 'auto',
        minHeight: '700px',
        display: 'block',
    };

    return <Layout>
        <div style={{padding: "0 20px"}}>
            <br/><br/>
            <h1 className="heading">School Committees</h1>

            <embed style={embedStyle} src="/SCHOOL-COMMITTEES.pdf"
                type="application/pdf"></embed>
                 <br/><br/>
        </div>
    </Layout>
}